import React from "react";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import "./foundation.scss";

import vw from '../components/img/vw.png';
import brigade152 from "../components/img/152.png";
import fiii from '../components/img/fiii.png'
import qrprivat from '../components/img/qrprivat.png'
import qrmono from '../components/img/qrmono.png'

import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { InstagramEmbed } from 'react-social-media-embed';
import { XEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';

function FoundationVW() {
    const navigate = useNavigate();

    const moreInfoRef = useRef(null);
    const donationRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fakeDataFetch = () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2500);
      };
  
      fakeDataFetch();
    }, []);

    return isLoading ? (
        <Loader />
        ) : (
        <>
            <div className="foundaion-page">
                <div className="foundation">
                    <div className="container">
                    <div className="foundation-info">
                        <div className="navbar-top">
                            <div className="brigade-info">
                                <div className="brigade-info_left-side">
                                    <img className="brigade-logo" src={brigade152}  />
                                </div>
                                <div className="brigade-info_right-side">
                                    <p className="brigade-title">152 Окрема <br />Єгерська Бригада</p>
                                </div>
                            </div>
                            <p className="foundation-background-nav">ЗБІР</p>
                            {/* <img className="author" src={fiii} alt="author"/> */}
                        </div>
                        
                        {/* <p className="foundation-background">ЗБІР</p> */}
                        <img className="vwt4" src={vw}/>

                        <div className="foundation-title-section">
                            <h1 className="foundation-title">VOLKSWAGEN T4 </h1>
                            <h2 className="foundation-subtitle">Для 152 ОЄБр</h2>
                            <div className="foundation-button-section">
                                <button className="foundation-button more-button" onClick={() => moreInfoRef.current?.scrollIntoView({
                                    behavior: 'smooth'
                                    }) }><i class="bi bi-info-square-fill"></i> Більше</button>
                                <button className="foundation-button donate-button" href="foundation-donation" onClick={() => donationRef.current?.scrollIntoView({
                                    behavior: 'smooth'
                                    }) }><i class="bi bi-cash-coin"></i> Долучитись</button>
                            </div>
                        </div>

                        <div className="down"><i className="bi bi-chevron-double-down"></i></div>

                    </div>


                    {/* <button href='' onClick={() => {navigate("/") }}> Home </button> */}
                    </div>
                </div>
            </div>




            <div className="foundation-description" ref={moreInfoRef} id="moreInfo">
                <div className="container">
                    <div className="social-media-links">
                        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <XEmbed url="https://x.com/vladyslavfiii/status/1829077319090069703" width={325} />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <InstagramEmbed url="https://www.instagram.com/p/C_OJgGNICsv/?img_index=1" width={328} />
                        </div> */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <YouTubeEmbed url="https://www.youtube.com/watch?v=w5Wx3D5LuGk&list=PLDwazpgvnEhw32UmDJgosuUbmYtMq9rnJ" width={1280} height={720} />
                        </div>
                        <div/>
                    </div>
                </div>
            </div>




            <div className="foundation-donation" ref={donationRef} id="donation">
                <div className="container">
                    <div className="card-section">

                        <div className="card card-privat">
                            <h2>ПриватБанк</h2>
                            <p>Посилання для поповнення: <a href="https://www.privat24.ua/send/d64m2" target="__blank">долучитись</a></p>
                            <p>Номер картки конверта: &nbsp; <span> 5168 7521 0562 2925</span></p>
                            <h4>Реквізити</h4>
                            <p>Отримувач: &nbsp; <span> ФІЛІМОНЧУК ВЛАДИСЛАВ ВІКТОРОВИЧ</span></p>
                            <p>IBAN: &nbsp; <span>UA463052990262076400969113133</span></p>
                            <p>РНОКПП/ЄДРПОУ: &nbsp; <span>3762607378</span></p>
                            <div className="qr-section">
                            <Link to="https://www.privat24.ua/send/d64m2" target="_blank">
                                    <img className="qr" src={qrprivat} />
                                </Link>
                            </div>
                        </div>
                        {/* ================= */}

                        <div className="card card-mono">
                            <h2>Монобанк</h2>
                            <p>Посилання для поповнення: <a href="https://send.monobank.ua/jar/8SSUkgjzjW" target="__blank">долучитись</a></p>
                            <p>Номер картки банки: &nbsp; <span>5375 4112 1881 6812</span></p>
                            <h4>Реквізити</h4>
                            <p>Отримувач: &nbsp; <span>ФІЛІМОНЧУК ВЛАДИСЛАВ ВІКТОРОВИЧ</span></p>
                            <p>IBAN: &nbsp; <span>UA493220010000026202349450653</span></p>
                            <p>РНОКПП/ЄДРПОУ: &nbsp; <span>3762607378</span></p>
                            <div className="qr-section">
                                <Link to="https://send.monobank.ua/jar/8SSUkgjzjW" target="_blank">
                                    <img className="qr" src={qrmono} />
                                </Link>
                            </div>
                        </div>

                    </div>
            
                </div>
            </div>




            <Footer />
        </>
    )
}

export default FoundationVW;
