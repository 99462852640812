import React from "react";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import "./foundation.scss";

import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";

function Foundation() {
    const navigate = useNavigate();

    const moreInfoRef = useRef(null);
    const donationRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fakeDataFetch = () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2500);
      };
  
      fakeDataFetch();
    }, []);

    return isLoading ? (
        <Loader />
        ) : (
        <>
            <div className="oops">
                <h1>OOPS...</h1>
                <button className="more-button" style={{width: 200+'px'}} href='' onClick={() => {navigate("/") }}> Збір </button>
            </div>
            <Footer />
        </>
    )
}

export default Foundation;
