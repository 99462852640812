import React from 'react';

import Header from '../components/Header';
import Portfolio from '../components/Portfolio';
import Footer from '../components/Footer'

function Home() {
    return (
        <>
            <Header />
            <Portfolio />
            <Footer />
        </>
    );
}

export default Home;