import React from "react";

import Home from "../../pages/Home"
import Foundation from "../../pages/Foundation";
import FoundationVW from "../../pages/FoundationVW";
import ManufactureOfDrones from "../../pages/ManufactureOfDrones";

import {BrowserRouter, Routes, Route, Navigate,} from "react-router-dom";

function App() {
    return (
         <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<ManufactureOfDrones />} />
                        <Route path="manufacture-of-drones" element={<Home />} />
                        <Route path="foundation" element={<Foundation />} />
                        <Route path="*" element={<Foundation />} />
                    </ Route>
                </ Routes>
        </ BrowserRouter>
    );
}

export default App;