import React from "react";
import "./loader.scss";

import fiii from '../../components/img/fiii.png'

const Loader = () => {
  return (
    <div className="loader">
        <img className="loader-logo" src={fiii} />
        <h1 className="loader-text">&nbsp;x&nbsp;</h1>
        <h1 className="loader-text">Щербина А.</h1>
        <h1 className="loader-text">&nbsp;x&nbsp;</h1>
        <h1 className="loader-text">Собченко О.</h1>
        <h1 className="loader-text">&nbsp;x&nbsp;</h1>
        <h1 className="loader-text">Малиш А.</h1>
    </div>
  );
};

export default Loader;