import React from "react";

import Footer from "../components/Footer";
import Loader from "../components/Loader";
import "./foundation.scss";

import vw from '../components/img/vw.png';
import drone from '../components/img/drone.png'
import fpvheader from '../components/img/fpv.png';
import fpv from '../components/img/fpvwhite.png';
import wingfpv from '../components/img/wingwhite.png';
import bomber from '../components/img/bomberwhite.png';

import drones1 from '../components/img/drones1.png';
import drones2 from '../components/img/drones2.png';
import drones3 from '../components/img/drones3.png';

import brigade152 from "../components/img/152.png";
import fiii from '../components/img/fiii.png'
import qrprivat from '../components/img/qrprivat.png'
import qrmono from '../components/img/qrmono.png'

import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { InstagramEmbed } from 'react-social-media-embed';
import { XEmbed } from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';

function ManufactureOfDrones() {
    const navigate = useNavigate();

    const moreInfoRef = useRef(null);
    const donationRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fakeDataFetch = () => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2500);
      };
  
      fakeDataFetch();
    }, []);

    useEffect(() => {
        document.title = 'Збір на Виробництво Дронів';
      }, []);

    return isLoading ? (
        <Loader />
        ) : (
        <>
            <div className="foundaion-page">
                <div className="foundation">
                    <div className="container">
                    <div className="foundation-info">
                        <div className="navbar-top">
                            {/* <div className="brigade-info">
                                <div className="brigade-info_left-side">
                                    <img className="brigade-logo" src={brigade152}  />
                                </div>
                                <div className="brigade-info_right-side">
                                    <p className="brigade-title">Дрони <br />ДРОНИ</p>
                                </div>
                            </div> */}

                            <p></p>
                            <p className="foundation-background-nav">ЗБІР</p>
                        </div>

                        <img className="foundation-background-image foundation-background-image-header" src={fpvheader} />

                        <div className="foundation-title-section">
                            <h1 className="foundation-subtitle">На Виробницвто</h1>
                            <h2 className="foundation-title">ДРОНІВ</h2>
                            <div className="foundation-button-section">
                                <button className="foundation-button more-button" onClick={() => moreInfoRef.current?.scrollIntoView({
                                    behavior: 'smooth'
                                    }) }><i class="bi bi-info-square-fill"></i> Більше</button>
                                <button className="foundation-button donate-button" href="foundation-donation" onClick={() => donationRef.current?.scrollIntoView({
                                    behavior: 'smooth'
                                    }) }><i class="bi bi-cash-coin"></i> Долучитись</button>
                            </div>
                        </div>

                        <div className="down"><i className="bi bi-chevron-double-down"></i></div>

                    </div>


                    {/* <button href='' onClick={() => {navigate("/") }}> Home </button> */}
                    </div>
                </div>
            </div>

            <div className="foundation-description" ref={moreInfoRef} id="moreInfo">
                <div className="container">
                    <div className="description">
                        
                        <div className="description-line">
                            <div className="description-image description-image-left-side">
                                <img className="foundation-background-image" src={drone}/>
                            </div>
                            <div className="foundation-description-text foundation-description-text-right">
                                <h3>Мета Збору</h3>
                                <p>Пропонуємо долучитися до збору на виготовлення дронів для наших військових.</p>
                                <p>Наші знайомі-інженери працюють безкоштовно, але їм потрібні гроші на закупівлю комплектуючих для дронів</p>
                            </div>
                        </div>
                        
                        <div className="description-line description-line-image-first">
                            <div className="foundation-description-text">
                                <h3>FPV-дрони</h3>
                                <p>Масове застосування FPV-дронів у бойових діях між українськими та російськими силами, кардинально змінило картину сучасної війни.</p>
                                <p>Вдале застосування одного або декількох дешевих дронів може знищити техніку вартістю мільйони доларів.</p>
                            </div>
                            <div className="description-image description-image-right-side">
                                <img className="foundation-background-image" src={fpv}/>
                            </div>
                        </div>

                        <div className="description-line">
                            <div className="description-image description-image-left-side">
                                <img className="foundation-background-image" src={wingfpv}/>
                            </div>
                            <div className="foundation-description-text foundation-description-text-right">
                                <h3>FPV-літакового типу</h3>
                                <p>Даний тип БПЛА використовуються для плавних польотів і ураження цілей на далеких відстанях.</p>
                            </div>
                        </div>
                        
                        <div className="description-line description-line-image-first">
                            <div className="foundation-description-text">
                                <h3>Бомбери</h3>
                                <p>Збільшена дальність та тривалість польоту за звичайний FPV,  що дозволяють завдавати ударів по цілях далеко за лінією фронту, вражаючи ворожу логістику, командні пункти та склади боєприпасів.</p>
                            </div>
                            <div className="description-image description-image-right-side">
                                <img className="foundation-background-image" src={bomber}/>
                            </div>
                        </div>
                        
                        <div className="description-line">
                            <h3 className="foundation-title section-title">Портфоліо</h3>  
                        </div>

                        <div className="description-line">
                          
                            <img className="drones-portfolio" src={drones1} />
                            <img className="drones-portfolio" src={drones2} />
                            <img className="drones-portfolio" src={drones3} />
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="foundation-donation" ref={donationRef} id="donation">
                <div className="container">

                    <div className="description-line">
                        <h3 className="foundation-title section-title">Долучитись</h3>  
                    </div>

                    <div className="card-section">

                        <div className="card card-mono">
                            <h2>Монобанк</h2>
                            <p><a style={{color: "#BC72E5"}} href="https://send.monobank.ua/jar/5NRnBKvARv" target="__blank">https://send.monobank.ua/jar/5NRnBKvARv</a></p>
                            <h4>Реквізити</h4>
                            <p>Отримувач: &nbsp; <span>Щербина А.</span></p>
                            <p>Номер банки: &nbsp; <span>4441 1111 2933 5232</span></p>
                            {/* <p>IBAN: &nbsp; <span>spaceholder</span></p> */}
                            {/* <p>РНОКПП/ЄДРПОУ: &nbsp; <span>spaceholder</span></p> */}
                            <div className="qr-section">
                                <Link to="https://send.monobank.ua/jar/5NRnBKvARv" target="_blank">
                                    <img className="qr" src={qrmono} />
                                </Link>
                            </div>
                        </div>
                        
                        <p className="donate-link-button"><a href="https://send.monobank.ua/jar/5NRnBKvARv" target="__blank">Посилання для поповнення банки MONOBANK</a></p>

                    </div>
            
                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export default ManufactureOfDrones;